<template>
  <div>

    <standing-order-type-form
      v-if="isSidebarActive"
      :is-sidebar-active.sync="isSidebarActive"
      :standing-order-type="standingOrderType"
      @form-submitted="formSubmitted"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="pageSize"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Press ENTER to search"
                @keyup.enter="refStandingOrderTypeTable.refresh();"
              />

              <b-button
                variant="primary"
                @click="openSidebar"
              >
                <span class="text-nowrap">Add Type</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refStandingOrderTypeTable"
        class="position-relative"
        :items="getList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="orderDesc"
      >
        <template #cell(name)="data">
          <p class=" d-block text-nowrap">
            {{ data.item.name }}
          </p>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editStandingOrderType(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="pageNumber"
              :total-rows="totalStandingOrderType"
              :per-page="pageSize"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BPagination, BRow, BTable } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useStandingOrderTypeList from '@/modules/standing-order-type/composables/useStandingOrderTypeList';
import StandingOrderTypeForm from '@/modules/standing-order-type/components/StandingOrderTypeForm.vue';

export default {
  setup() {
    const {
      pageNumber,
      dataMeta,
      getList,
      orderDesc,
      pageSize,
      perPageOptions,
      refStandingOrderTypeTable,
      searchQuery,
      orderBy,
      tableColumns,
      totalStandingOrderType,
    } = useStandingOrderTypeList();

    const emptyStandingOrderType = { id: null, name: null };
    const isSidebarActive = ref(false);

    const standingOrderType = ref({ ...emptyStandingOrderType });

    const editStandingOrderType = (standingOrderTypeSelected) => {
      standingOrderType.value = { ...standingOrderTypeSelected };
      isSidebarActive.value = true;
    };

    const formSubmitted = () => {
      isSidebarActive.value = false;
      standingOrderType.value = { ...emptyStandingOrderType };
      refStandingOrderTypeTable.value.refresh();
    };

    const openSidebar = () => {
      standingOrderType.value = { ...emptyStandingOrderType };
      isSidebarActive.value = true;
    };

    return {
      // Reactive
      isSidebarActive,
      standingOrderType,

      //Method
      editStandingOrderType,
      formSubmitted,
      openSidebar,

      // UseStandingOrderList
      pageNumber,
      dataMeta,
      getList,
      orderDesc,
      pageSize,
      perPageOptions,
      refStandingOrderTypeTable,
      searchQuery,
      orderBy,
      tableColumns,
      totalStandingOrderType,
    };
  },
  components: {
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTable,
    StandingOrderTypeForm,
    vSelect,
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
