import { computed, ref, watch } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import useStandingOrderType from './useStandingOrderType';

const useStandingOrderList = () => {
  const { getStandingOrderList } = useStandingOrderType();

  const perPageOptions = [10, 25, 50, 100];
  const tableColumns = [{ key: 'name', sortable: true }, { key: 'actions' }];

  const pageNumber = ref(1);
  const orderDesc = ref(true);
  const pageSize = ref(10);
  const refStandingOrderTypeTable = ref(null);
  const searchQuery = ref(null);
  const orderBy = ref('id');
  const totalStandingOrderType = ref(0);

  const dataMeta = computed(() => {
    const localItemsCount = refStandingOrderTypeTable.value
      ? refStandingOrderTypeTable.value.localItems.length
      : 0;
    return {
      from: pageSize.value * (pageNumber.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (pageNumber.value - 1) + localItemsCount,
      of: totalStandingOrderType.value,
    };
  });

  watch([pageNumber, pageSize], () => {
    refStandingOrderTypeTable.value.refresh();
  });

  const getList = (_, callback) => {
    const params = {
      orderBy: orderBy.value,
      orderDesc: orderDesc.value,
      pageSize: pageSize.value,
      pageNumber: pageNumber.value,
      searchQuery: searchQuery.value,
    };

    getStandingOrderList(params).then(({ standingOrderTypes, total }) => {
      callback(standingOrderTypes);
      totalStandingOrderType.value = total;
    });
  };

  return {
    // Const
    perPageOptions,
    tableColumns,

    // Reactive
    pageNumber,
    orderDesc,
    pageSize,
    refStandingOrderTypeTable,
    searchQuery,
    orderBy,
    totalStandingOrderType,

    // Computed
    dataMeta,

    // Method
    getList,
  };
};

export default useStandingOrderList;
